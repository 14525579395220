import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import Layout from '@/views/layout'

Vue.use(VueRouter)

const routes = [
  {
    path:'/test',
    name: 'Test',
    component: () => import('@/views/test.vue'),
  },
  // 邀请
  {
    path: '/collect',
    name: 'Collect',
    component: () => import('@/views/collect/index'),
    hidden: true,
  },
  // 打印
  {
    path: '/printer',
    name: 'Printer',
    component: () => import('@/views/activity/components/countPrintView.vue'),
    hidden: true,
  },
  // 三优评比
  {
    path: '/TEA',
    name: 'TEA',
    redirect: '/TEA/index',
    component: () => import('@/views/TEA/layout'),
    children: [
      {
        path: 'index',
        name: 'TEAindex',
        component: () => import('@/views/TEA/index'),
      }
    ]
  },
  // 外部学习
  {
    path: '/wb-study',
    name: 'WBstudy',
    component: () => import('@/views/study/wb-details.vue'),
    hidden: true,
  },
  {
    path: '/',
    name: '',
    redirect: 'login',
  },
  // 登录
  {
    path: '/login',
    name: 'Login',
    component: ()=> import('@/views/login/index'),
    hidden: true
  },
  // 账户信息
  {
    path: '/',
    component: Layout,
    // redirect: '/accountInfo',
    alwaysShow: true,
    children: [
      {
        path: 'accountInfo',
        name: 'AccountInfo',
        component: () => import('@/views/accountInfo/index'),
        meta: { title: '账户信息', icon: 'el-icon-user', affix: true }
      }
    ]
  },
  // 首页
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import('@/views/home/index'),
        meta: { title: '首页', icon: 'el-icon-s-home', affix: true }
      }
    ]
  },
  // 管理中心
  {
    path:'/basic',
    name:'basic',
    redirect: '/basic/school',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: '管理中心',
      icon: 'el-icon-coin',
    },
    children: [
      {
        path: 'school',
        name: 'School',
        component: () => import('@/views/basic/schools'),
        meta: { title: '学校管理', icon: 'el-icon-school'}
      },
      {
        path: 'class',
        name: 'Class',
        component: () => import('@/views/basic/class'),
        meta: { title: '班级管理', icon: 'el-icon-class'}
      },
      {
        path: 'teacher',
        name: 'Teacher',
        component: () => import('@/views/basic/teachers'),
        meta: { title: '教师管理', icon: 'el-icon-user-solid'}
      },
      {
        path: 'menu',
        name: 'Menu',
        component: () => import('@/views/system/menu'),
        meta: { title: '菜单管理', icon: 'el-icon-menu'}
      },
      {
        path: 'role',
        name: 'Role',
        component: () => import('@/views/system/role'),
        meta: { title: '角色管理', icon: 'el-icon-s-custom'}
      },
      {
        path: 'staff',
        name: 'Staff',
        component: () => import('@/views/basic/staff'),
        meta: { title: '教研员管理', icon: 'el-icon-s-custom'}
      },
      {
        path: 'embu',
        name: 'EmbuManagement',
        component: () => import('@/views/basic/embu.vue'),
        meta: { title: '评价量表管理', icon: 'el-icon-s-line'}
      },
      {
        path: 'expert',
        name: 'Expert',
        component: () => import('@/views/basic/expert.vue'),
        meta: { title: '专家管理', icon: 'el-icon-s-line'}
      },
      // 三优评比主活动
      {
        path: 'teaMain',
        name: 'TEAMainCreat',
        component: () => import('@/views/basic/TEA/main'),
        meta: { title: '三优评比主活动创建', icon: 'el-icon-s-custom'}
      },
      // 三优评比子活动
      {
        path: 'teaSub',
        name: 'TEASubCreat',
        component: () => import('@/views/basic/TEA/sub'),
        meta: { title: '三优评比子活动创建', icon: 'el-icon-s-custom'}
      },
    ]
  },
  // 资源中心
  {
    path: '/resource',
    component: Layout,
    // redirect: '/resource/index',
    children: [
      {
        path: 'index',
        name:'Resource',
        component: () => import('@/views/resource/index'),
        meta: { title: '全部资源', icon: 'el-icon-resource', keepAlive: true }
      },
      {
        path: 'detail',
        name:'ResourceDetail',
        component: () => import('@/views/resource/details'),
        meta: { title: '资源详情', icon: 'el-icon-resource' }
      },
      {
        path: 'expert-detail',
        name:'ExpertResourceDetail',
        component: () => import('@/views/resource/expertDetails'),
        meta: { title: '专家资源详情', icon: 'el-icon-resource' }
      },
      {
        path: 'edit',
        name:'ResourceEdit',
        component: () => import('@/views/resource/edit'),
        meta: { title: '资源编辑', icon: 'el-icon-resource' }
      },
      {
        path: 'addition',
        name:'ResourceAddition',
        component: () => import('@/views/resource/addition'),
        meta: { title: '资源上传', icon: 'el-icon-resource' }
      },
      {
        path: 'study',
        name:'ResourceStudy',
        component: () => import('@/views/resource/study'),
        meta: { title: '内部资源', icon: 'el-icon-resource' }
      },
      {
        path: 'added',
        name:'ResourceAdded',
        component: () => import('@/views/resource/added'),
        meta: { title: '资源上传', icon: 'el-icon-resource' }
      }
    ]
  },
  // 活动中心
  {
    path:'/activity',
    name:'activity',
    redirect: '/activity/expert',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: '活动中心',
      icon: 'el-icon-coin',
    },
    children: [
      {
        path: 'expert',
        name: 'ActivityExpert',
        component: () => import('@/views/activity/experts'),
        meta: { title: '往期活动', icon: 'el-icon-user-solid'}
      },
      {
        path: 'expertDetail',
        name: 'ExpertDetail',
        component: () => import('@/views/activity/expertDetails'),
        meta: { title: '专家活动详情', icon: 'el-icon-user-solid', activeMenu:'/activity/expert'}
      },
      {
        path: 'themeDetail',
        name: 'ThemeDetail',
        component: () => import('@/views/activity/themeDetails'),
        meta: { title: '主题活动详情', icon: 'el-icon-user-solid', activeMenu:'/activity/expert'}
      },
      {
        path: 'teacher',
        name: 'ActivityTeacher',
        component: () => import('@/views/activity/teachers'),
        meta: { title: '研修任务', icon: 'el-icon-user-solid'}
      },
      {
        path: 'uploadOpus',
        name: 'UploadOpus',
        component: () => import('@/views/activity/uploadOpus'),
        meta: { title: '上传资源素材', icon: 'el-icon-user-solid', activeMenu:'/activity/teacher'}
      },
      {
        path: 'schoolUpload',
        name: 'SchoolUpload',
        component: () => import('@/views/activity/schoolUpload'),
        meta: { title: '上传资源素材', icon: 'el-icon-user-solid', activeMenu:'/activity/teacher'}
      },
      {
        path: 'report',
        name: 'ActivityReport',
        component: () => import('@/views/activity/report'),
        meta: { title: '任务统计报告', icon: 'el-icon-user-solid'}
      },
    ]
  },
  // 专家评审
  {
    path: '/reviewed',
    component: Layout,
    // redirect: '/resource/index',
    children: [
      {
        path: 'index',
        name:'Reviewed',
        component: () => import('@/views/reviewed/index'),
        meta: { title: '研修任务评审', icon: 'el-icon-review' }
      },
      {
        path: 'teacher',
        name:'ReviewTeacher',
        component: () => import('@/views/reviewed/teachers'),
        meta: { title: '评审列表', icon: 'el-icon-review' }
      },
      {
        path: 'audited',
        name:'ReviewAudited',
        component: () => import('@/views/reviewed/audited'),
        meta: { title: '评审', icon: 'el-icon-review' }
      },
      {
        path: 'matching',
        name:'ReviewMatch',
        component: () => import('@/views/reviewed/matching'),
        meta: { title: '评审任务分配', icon: 'el-icon-review' }
      },
      {
        path: 'counting',
        name:'ReviewCount',
        component: () => import('@/views/reviewed/counting'),
        meta: { title: '统计', icon: 'el-icon-s-data' }
      },
      {
        path: 'results-enter',
        name:'ReviewResultsEnter',
        component: () => import('@/views/reviewed/resultsEnter'),
        meta: { title: '结果录入', icon: 'el-icon-review' }
      },
      {
        path: 'tentative',
        name:'ReviewFilter',
        component: () => import('@/views/reviewed/tentative'),
        meta: { title: '研修任务初筛', icon: 'el-icon-document' }
      },
      {
        path: 'tentative/list',
        name:'ReviewFilterList',
        component: () => import('@/views/reviewed/tentativeList'),
        meta: { title: '初筛列表', icon: 'el-icon-document', keepAlive: false }
      },
      {
        path: 'TEA-PS',
        name:'TEA-PS',
        component: () => import('@/views/reviewed/TEA-PS'),
        meta: { title: '三优活动初筛', icon: 'el-icon-document' }
      },
      {
        path: 'TEA-Eval',
        name:'TEA-Eval',
        component: () => import('@/views/reviewed/TEA-Eval'),
        meta: { title: '三优活动评审', icon: 'el-icon-document' }
      },
    ]
  },
  // 学习任务
  {
    path: '/study',
    component: Layout,
    children: [
      {
        path: 'index',
        name:'Study',
        component: () => import('@/views/study/index'),
        meta: { title: '学习任务', icon: 'el-icon-school'}
      },
      {
        path: 'list',
        name:'StudyList',
        component: () => import('@/views/study/list'),
        meta: { title: '列表', icon: 'el-icon-school', keepAlive: true }
      },
      {
        path: 'detail',
        name:'StudyDetail',
        component: () => import('@/views/study/details'),
        meta: { title: '详情', icon: 'el-icon-school' }
      },
    ]
  },
  // 报告
  // {
  //   path: '/report',
  //   component: Layout,
  //   children: [
  //     {
  //       path: 'area',
  //       name: 'AreaReport',
  //       component: () => import('@/views/report/area'),
  //     },
  //     {
  //       path: 'school',
  //       name: 'SchoolReport',
  //       component: () => import('@/views/report/school'),
  //     }
  //   ]
  // },
]

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior: () => ({ y: 0 }),
})

router.beforeEach((to, from, next) => {
  // console.error(to, from, '=== created == beforeEach ===');
  const matcheds = to.matched||[];
  const {name} = matcheds[0]||{};
  // 添加缓存页面
  const names = store.getters.keepAlivePageName;
  if (to.name==='Resource'&&!names.includes('Resource')) {
    store.dispatch('setKeepAlivePageName', [...names, 'Resource']);
  }
  const otherRouterNames = [
    'ExpertDetail',
    'ThemeDetail',
    'UploadOpus',
    'SchoolUpload',
    'ResourceDetail',
    'ExpertResourceDetail',
    'ResourceEdit',
    'ResourceAddition',
    'ResourceAdded',
    'ReviewTeacher',
    'ReviewAudited',
    'ReviewCount',
    'ReviewFilterList',
    'ReviewResultsEnter',
    'StudyList',
    'StudyDetail',
    'AccountInfo',
    'Collect',
    'Printer',
    'WBstudy',
    'AreaReport',
    'SchoolReport',
    'Test',
  ];
  if(otherRouterNames.includes(to.name)){
    to.meta.activeMenu = from.meta.activeMenu||from.path||to.meta.activeMenu;
  }
  // !/.*\/collect\/.*/.test(to.path)
  if (to.name != 'Login' && !localStorage.getItem("token") && to.name != 'Collect' && name!='TEA' && name!='WBstudy') {
    next({
      name: 'Login'
    })
  } else {
    const { menu } = store.state;
    if (!otherRouterNames.includes(to.name) && to.name != 'Login' && menu && !hasValue(menu, to.path) && name!='TEA') {
      next('/home');
      // Message.error({
      //   message:"暂无权限访问！",
      // });
      return false;
    }
    next();
  }
})

const hasValue = (arr=[], target='') =>{
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];
    if (item.uri === target) {
      return true;
    } else if (Array.isArray(item.childs)) {
      if (hasValue(item.childs, target)) {
        return true;
      }
    }
  }
  return false;
}

export default router

