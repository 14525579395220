import request from "../axios";

// 七牛云Token
export function getQiniuToken(params) {
  return request({
    url: "/communal/qiniu",
    method: "get",
    params,
  });
}
// 获取七牛云预览token
export function getQiniuUpload(params) {
  return request({
    url: "/communal/qiniu-upload",
    method: "get",
    params,
  });
}
// 七牛云持久化储存
export function qiniuFops(params) {
  return request({
    url: "/communal/qiniu-persistence",
    method: "get",
    params,
  });
}
// 获取阿里云临时授权
export function aliyunSample(params) {
  return request({
    url: "/communal/aliyun-sample",
    method: "get",
    params,
  });
}

// 获取教育集团
export function getGroup(params) {
  return request({
    url: "/communal/group",
    method: "get",
    params,
  });
}
// 获取学校
export function getSchool(params) {
  return request({
    url: "/communal/school",
    method: "get",
    params,
  });
}
// 获取分配区
export function getThreeArea(params) {
  return request({
    url: "/communal/three-area",
    method: "get",
    params,
  });
}

// 教师列表
export function getTeachers(params) {
  return request({
    url: "/communal/teachers",
    method: "get",
    params,
  });
}

// 获取年级
export function getGrade(params) {
  return request({
    url: "/communal/grade",
    method: "get",
    params,
  });
}
// 无需token获取年级
export function getGlobalGrade(params) {
  return request({
    url: "/grade",
    method: "get",
    params,
  });
}

// 获取班级
export function getClass(params) {
  return request({
    url: "/communal/class",
    method: "get",
    params,
  });
}

// 无需token获取班级
export function getGlobalClass(params) {
  return request({
    url: "/class",
    method: "get",
    params,
  });
}

// 获取菜单类型数据
export function getMenuType(params) {
  return request({
    url: "/communal/menutype",
    method: "get",
    params,
  });
}

// 获取省
export function getProvince(params) {
  return request({
    url: "/communal/province",
    method: "get",
    params,
  });
}

// 获取市
export function getCity(params) {
  return request({
    url: "/communal/city",
    method: "get",
    params,
  });
}

// 获取区
export function getArea(params) {
  return request({
    url: "/communal/area",
    method: "get",
    params,
  });
}

// 获取学段
export function getPeriod(params) {
  return request({
    url: "/communal/period",
    method: "get",
    params,
  });
}

// 获取学科
export function getSubject(params) {
  return request({
    url: "/communal/subject",
    method: "get",
    params,
  });
}

// 获取教师职称
export function getJobs(params) {
  return request({
    url: "/communal/jobs",
    method: "get",
    params,
  });
}

// 获取教师班级职务
export function getClassDuty(params) {
  return request({
    url: "/communal/class-duty",
    method: "get",
    params,
  });
}

// 获取专家信息
export function getExpert(params) {
  return request({
    url: "/communal/expert",
    method: "get",
    params,
  });
}

// 获取分析范围
export function getShareScope(params) {
  return request({
    url: "/communal/share-scope",
    method: "get",
    params,
  });
}

// 获取资源类型
export function getResourceType(params) {
  return request({
    url: "/communal/resource-type",
    method: "get",
    params,
  });
}

// 获取教研员职务
export function getStaffJob(params) {
  return request({
    url: "/communal/staff-job",
    method: "get",
    params,
  });
}

// 获取教研员学段
export function getStaffPhase(params) {
  return request({
    url: "/communal/staff-phase",
    method: "get",
    params,
  });
}

// 获取微信文章
export function getWeChatArticle(params) {
  return request({
    url: "/communal/article",
    method: "get",
    params,
  });
}
// 获取评审等级
export function getLevel(params) {
  return request({
    url: "/communal/auth-level",
    method: "get",
    params,
  });
}
// 获取维度主体评审类型
export function getEvaluateType(params) {
  return request({
    url: "/communal/evaluate-type",
    method: "get",
    params,
  });
}
