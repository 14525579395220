import request from "../axios";

// 登录
export function loginFn(data) {
  return request({
    url: "/auth/login",
    method: "post",
    data,
  });
}

// 退出登录登录
export function logoutFn(params) {
  return request({
    url: "/auth/logout",
    method: "get",
    params,
  });
}

// 角色选择
export function roleIdentify(data) {
  return request({
    url: "/auth/select-roles",
    method: "post",
    data,
  });
}

// 获取权限数据
export function getAuth(params) {
  return request({
    url: "/auth/route",
    method: "get",
    params,
  });
}

// 修改个人信息
export function updateUserInfo(data) {
  return request({
    url: "/auth/edit",
    method: "post",
    data,
  });
}
// 修改密码
// export function updatePassword(data) {
//   return request({
//     url: "/auth/edit",
//     method: "post",
//     data,
//   });
// }

// 发送验证码
export function sendSMSCode(data) {
  return request({
    url: "/auth/sms/send",
    method: "post",
    data,
  });
}
// 找回密码
export function smsRetrieve(data) {
  return request({
    url: "/auth/sms/retrieve",
    method: "post",
    data,
  });
}



