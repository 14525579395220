import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')):null,
    menu: localStorage.getItem('menu')?JSON.parse(localStorage.getItem('menu')):null,
    permission: localStorage.getItem('permission')?JSON.parse(localStorage.getItem('permission')):null,
    keepAlivePageName:[],
  },
  getters: {
    userInfo (state) {
      return state.user ? state.user : {
        avatar:'',
        email: '',
        from_type:'',
        id: '',
        name: '',
        phone: '',
        remark:'',
        roles:[],
        sex:'',
        status: '',
        username:''
      };
    },
    username (state) {
      return state.user ? state.user.username : "";
    },
    email (state) {
      return state.user? state.user.email : "";
    },
    roleInfo (state) {
      return state.user? state.user.roles : [];
    },
    menu (state) {
      return state.menu ? state.menu : [];
    },
    permission (state) {
      return state.permission ? state.permission : [];
    },
    keepAlivePageName (state) {
      return state.keepAlivePageName ? state.keepAlivePageName : [];
    },
  },
  mutations: {
    SET_USER (state, val) {
      state.user = val;
    },
    SET_MENU (state, val) {
      state.menu = val;
    },
    SET_PERMS (state, val) {
      state.permission = val;
    },
    SET_SIDE_BAR (state, val) {
      state.sideBar = val;
    },
    SET_KEEP_ALIVE_PAGE_NAME(state, val){
      state.keepAlivePageName = val
    },
  },
  actions: {
    setUser ({ commit }, val) {
      commit("SET_USER", val);
    },
    setMenu ({ commit }, val) {
      commit("SET_MENU", val);
    },
    setPerms ({ commit }, val) {
      commit("SET_PERMS", val);
    },
    setSideBar ({ commit }, val) {
      commit("SET_SIDE_BAR", val);
    },
    setKeepAlivePageName ({ commit }, val) {
      commit("SET_KEEP_ALIVE_PAGE_NAME", val);
    },
  },
  modules: {
  }
})
